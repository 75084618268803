.home {
  flex-grow: 1;

  .header {
    width: 60%;
    margin-bottom: 200px;

    h1 {
      font-family: 'IBMPlexSans-Bold', sans-serif;
    }

    span {
      color: black;
    }

    p {
      color: gray;
    }

    h1, h2 {
      color: white;
    }

    .input {
      width: 60%;
      background-color: pink;

    }
  }

  .projects {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(275px, 1fr));
    grid-auto-rows: minmax(auto, 1fr);
    grid-gap: 50px;

    .project {
      border-radius: 20px;
      padding: 30px;
      background-color: white;
      color: black;
      text-align: center;

      display: flex;
      flex-direction: column;
      justify-content: center;

      h3 {
        position: relative;
        //display: inline;

        margin-bottom: 20px;
        font-family: 'IBMPlexSans-Bold';
        //background-color: red;

        :last-child {
          cursor: pointer;
        }
      }

      svg {
        position: absolute;
        bottom: 3px;
      }

      p {
        margin-bottom: 10px;
      }

      a, a:active, a:link {
        text-decoration: underline;
        color: black;
      }
    }
  }
}

@media screen and (max-width: 1150px) {
  //.home {
  //  background-color: red;
  //
  //  .header {
  //    width: 100%;
  //
  //  }
  //
  //  // .MuiFormControl-fullWidth {
  //  //   width: 100%;
  //  // }
  //}
}