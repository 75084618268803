.adminConsole {
  flex-grow: 1;

  margin: 30px 0;

  display: flex;
  align-items: flex-start;
  gap: 30px;
}

.modal {
  background-color: #006DFF;
  border-radius: 20px;
  color: white;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 300px;

  p:nth-child(2n) {
    margin-top: 20px;
  }

  p:nth-child(2n + 1) {
    opacity: 0.7;
  }
}