@import './variables.scss';

@font-face {
  font-family: "IBMPlexSans-Regular";
  src: local("IBMPlexSans-Regular"), url("../fonts/IBMPlexSans-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "IBMPlexSans-Bold";
  src: local("IBMPlexSans-Bold"), url("../fonts/IBMPlexSans-Bold.ttf") format("truetype");
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'IBMPlexSans-Regular', sans-serif;
}

h1 {
  font-family: 'IBMPlexSans-Bold', sans-serif;
}

a:link, a:visited, a:hover, a:active, a:focus {
  text-decoration: none;
  color: white;
}

// ::-webkit-scrollbar {
//   width: 25px;
// }

// ::-webkit-scrollbar-track {
//   background-color: red;
// }

// ::-webkit-scrollbar-thumb {
//   background-color: yellow;
// }

.layout {
  min-height: 100vh;
  padding: 0 $page-padding;
  background-image: url('../assets/background.png');
  background-size: cover;

  display: flex;
  flex-direction: column;

  // background-color: red;

  .navbar {
    width: 100%;
    margin-bottom: 10px;

    display: flex;
    justify-content: space-between;
    align-items: center;

    position: relative;

    // background-color: green;
  
    padding: 20px 0;
  
    img {
      height: 20px;
      cursor: pointer;
    }
  
    .links {
      //width: 10%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      //background-color: yellow;

      :not(a) {
        cursor: pointer;
      }
    }
  
    a:not(:last-child) {
      margin-right: 20px;
    }
  }

  .footer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    margin-bottom: 30px;
    margin-top: 50px;
    color: white;
    text-decoration: underline;

    img {
      margin: 10px 0;
    }
  }

  .alert {
    width: 30%;
    position: absolute;
    top: 10px;
    left: 10px;
  }
}