.contact {
  flex-grow: 1;
  color: white;

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;

  .header {

    h1 {
      text-align: center;
    }

    .list {
      margin: 20px;
      display: flex;
      justify-content: space-around;
      gap: 50px;

      .contactMethod {
        display: flex;
        flex-direction: column;
        align-items: center;

        cursor: pointer;
      }
    }
  }
}

$line-width: 0px;
$line-margin: 50px;

.content {
  width: 60%;
  display: flex;
  

  // display: grid;
  // grid-template-columns: 1fr calc(2 * #{$line-margin} + #{$line-width}) 1fr;
  // grid-template-rows: auto 1fr;

  //background-color: red;

  .contactMethod {
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;

    cursor: pointer;
    //background-color: red;
  }


  .form {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;

    //background-color: blue;
  }

  .email {
    //background-color: yellow;
  }

  .line {
    width: $line-width;
    margin: 0 $line-margin;
  }


}