.invitations {
  flex-grow: 1;
  color: white;
  
  span, button {
    font-family: 'IBMPlexSans-Bold';
  }

  .header {
    width: 60%;
  }

  .input {
    width: 60%;
    margin-top: 20px;

    display: flex;
    justify-content: space-between;
    gap: 20px;

    :first-child {
      flex-grow: 4;
    }

    :last-child {
      flex-grow: 1;
    }
  }

  .list {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(275px, 1fr));
    grid-auto-rows: minmax(auto, 1fr);
    grid-gap: 10px;

    margin: 50px 0;

    .invitation {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;

      border-radius: 20px;
      background-color: white;
      color: black;

      h3 {
        margin: 10px;
      }

      p:nth-child(even) {
        color: #6F6F6F;

        &:not(:last-of-type) {
          margin-bottom: 10px;
        }
      }

      .header {
        width: 100%;
        padding: 10px 20px;
        border-radius: 20px 20px 0 0;

        display: flex;
        justify-content: center;
        align-items: center;

        p {
          font-family: 'IBMPlexSans-Bold';
        }
      }

      .footer {
        width: 100%;
        padding: 20px;

        display: flex;
        justify-content: space-around;
        gap: 20px;

        * {
          max-width: 200px;
          flex-grow: 1;
        }
      }
    }
  }
}